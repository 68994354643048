import { useEffect, useState } from "react"
import { OptionGroup } from "./OptionGroup"
import { OptionGroupSelection } from "./interface"
import { MenuItemOption } from "@models/menuItemOption"
import { Button, Column, Icon, IconKey, Modal, ModalController } from "@openui"

interface Props {
  controller: ModalController
  option: MenuItemOption
  initialSelections: Record<number, OptionGroupSelection>
  onSubmit: (selections: Record<number, OptionGroupSelection>) => void
}

export const NestedOptionGroups = ({
  initialSelections,
  controller,
  option,
  onSubmit,
}: Props) => {
  const [shouldValidate, setShouldValidate] = useState(false)
  const [selections, setSelections] =
    useState<Record<number, OptionGroupSelection>>(initialSelections)
  const handleSubmit = () => {
    for (const optionGroup of option.nestedOptionGroups) {
      if (
        optionGroup.maxSelections &&
        Object.keys(selections[optionGroup.id]?.options ?? {}).length >
          optionGroup.maxSelections
      ) {
        setShouldValidate(true)
        return
      }
      if (
        optionGroup.minSelections &&
        Object.keys(selections[optionGroup.id]?.options ?? {}).length <
          optionGroup.minSelections
      ) {
        setShouldValidate(true)
        return
      }
      onSubmit(selections)
    }
  }
  useEffect(() => {
    setShouldValidate(false)
  }, [controller.isOpen])
  return (
    <Modal
      controller={controller}
      padding="0px"
      right={
        <Icon
          iconKey={IconKey.Close}
          onClick={() => {
            setSelections(initialSelections)
            controller.close()
          }}
          size={20}
        />
      }
      footer={
        <Column width="100%" padding="12px">
          <Button width="100%" onClick={handleSubmit}>
            Continue
          </Button>
        </Column>
      }
    >
      {option.nestedOptionGroups.map((og) => (
        <OptionGroup
          key={og.id}
          optionGroup={og}
          selections={selections[og.id]}
          validate={shouldValidate}
          onUpdate={(optionGroupSelections) =>
            setSelections((prev) => ({
              ...prev,
              [og.id]: optionGroupSelections,
            }))
          }
        />
      ))}
    </Modal>
  )
}
